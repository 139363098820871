import * as React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import { StaticImage } from "gatsby-plugin-image";
import Seo from "../components/seo";
import Layout from "../components/layout";

import "../styles/global/base.scss";
import "../styles/about.scss";

import Img1 from "../svgs/about_capa.svg";
import Img2 from "../svgs/about_capaBg.svg";

const AboutPage = () => {
  return (
    <React.Fragment>
      <Seo title="ABOUT" pageClass="aboutPage" />
      <Layout>
        <Container fluid className="aboutHeader">
          <Row>
            <Col lg={{ span: 6, offset: 1 }} className="overview">
              <h1>ABOUT</h1>
              <h2>【Montage】<span>mɑntά&nbsp;&nbsp;ːʒ</span></h2>
              <Col lg={8}>
                <p>視点の異なる複数のカットを組み合わせて、ひとつの連続したシーン・ストーリーを構築する技法</p>
                <p>28年前、私たちモンタージュは劇映画とドキュメンタリー映画を制作していたメンバーがコアとなり会社としてスタートさせました。<br />世界が加速度的に有機的に融合する、あるいは分断していく時代に、私たちは世界中の感性やテクノロジーを組み合わせ、再構築し、新しい価値を生み出すことを目指していきます。</p>
              </Col>
            </Col>
            <Col lg={5} className="overviewImgs">
              <Col xs={{ span: 9, offset: 3 }} lg={{ span: 10, offset: 2 }}>
                <div>
                  <StaticImage src="../images/about/about_01.jpg" alt="" layout="constrained" objectPosition={"50% 50%"} />
                </div>
              </Col>
              <Col xs={9} lg={{ span: 10 }}>
                <div>
                  <StaticImage src="../images/about/about_02.png" alt="" layout="constrained" objectPosition={"0% 0%"} />
                </div>
              </Col>
            </Col>
          </Row>
        </Container>
        <Container className="aboutCapabilities" fluid>
          <Row>
            <Col xs={12}>
              <h2 className="titleTopBorder">Capabilities</h2>
            </Col>
            <Col lg={{ span: 4, offset: 1 }} className="capaImg">
              <Img1 />
              <Img2 className="bg" />
            </Col>
            <Col lg={{ span: 4, offset: 1 }}>
              <ul>
                <li>
                  <h3>Production Work</h3>
                  <p>
                    さまざまなメディアにおける映像企画、広告、イベント映像などに加え、コンセプト映像、インスタレーションなど、映像に関わる全てのデザインワークに対応します。ドキュメンタリー映画の企画・制作も行っています。
                  </p>
                </li>
                <li>
                  <h3>Creative Planning</h3>
                  <p>
                    制作・編集・撮影など社内のプロフェッショナルスタッフとさまざまな国内外の協力会社とのネットワークで、状況に応じた最適なチームを編成し、お客様のご要望にお応えします。
                  </p>
                </li>
                <li>
                  <h3>Experiential Design</h3>
                  <p>
                    映像を取り巻く環境をトータルに演出します。視るだけでなく体感する空間デザインと体験設計を生み出し映像表現の可能性を追求していきます。
                  </p>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
        <Container fluid className="aboutCompany p-0">
          <div className="w-100 grid-2-9 grid-sm-2-10">
            <h2 className="titleTopBorder">Company</h2>
          </div>
          <div className="grid-10-26 grid-sm-2-10">
            <div className="companyImg w-100">
              <StaticImage src="../images/about/office.jpg" alt="" layout="constrained" />
            </div>
          </div>
          <div className="grid-10-25 grid-sm-2-9 companyDetail">
            <div className="grid-1-9 header">
              <h2>株式会社モンタージュ</h2>
              <span>MONTAGE Inc.</span>
            </div>
            <div className="grid-1-9">
              <ul>
                <li>
                  <h4>所在地</h4>
                  <div>
                    〒156-0057<br />東京都世田谷区上北沢4-10-18
                  </div>
                </li>
                <li>
                  <h4>TEL</h4>
                  <div>03-3303-9871（代表）</div>
                </li>
                <li>
                  <h4>FAX</h4>
                  <div>03-3303-9824</div>
                </li>
                <li>
                  <h4>E-mail</h4>
                  <div><a href="mailto:desk@montage.co.jp">desk@montage.co.jp</a></div>
                </li>
                <li>
                  <h4>会社設立</h4>
                  <div>1994年6月</div>
                </li>
                <li>
                  <h4>資本金</h4>
                  <div>10,000,000円</div>
                </li>
                <li>
                  <h4>代表取締役会長</h4>
                  <div>住田 望</div>
                </li>
                <li>
                  <h4>代表取締役社長</h4>
                  <div>小松原 時夫</div>
                </li>
                <li>
                  <h4>取締役</h4>
                  <div>芹澤 博雅</div>
                </li>
                <li>
                  <h4>従業員</h4>
                  <div>22名</div>
                </li>
                <li>
                  <h4>取引銀行</h4>
                  <div>城南信用金庫 砧支店 / 三菱UFJ銀行 永福町支店、成城学園前支店、上北沢支店 / みずほ銀行 北沢支店 / 三井住友銀行 経堂支店</div>
                </li>
                <li>
                  <h4>主要加盟団体</h4>
                  <ul>
                    <li>公益法人映像文化製作者連盟</li>
                    <li>財団法人日本視聴覚教育協会</li>
                    <li>THE INTERNATIONAL DOCUMENTARY ASSOCIATION</li>
                  </ul>
                </li>
                <li>
                  <h4>業務内容</h4>
                  <ul>
                    <li>
                      <h5>映像企画・製作</h5>
                      <ul>
                        <li>イベント映像、インスタレーション、CM、PV、MV、Webムービー、ドキュメンタリー、テレビ番組、デジタルサイネージ</li>
                      </ul>
                    </li>
                    <li>
                      <h5>空間演出</h5>
                      <ul>
                        <li>展示会、イベント施設、アミューズメント施設、文化施設、ステージ映像</li>
                      </ul>
                    </li>
                    <li>
                      <h5>デジタルコンテンツ企画・製作</h5>
                      <ul>
                        <li>VR、AR、MR、Web系コンテンツ</li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </Container>
        <Container fluid className="baseGrid aboutAccess">
          <div className="grid-2-9 grid-sm-2-10">
            <h2 className="titleTopBorder">Access</h2>
          </div>
          <div className="grid-10-26 grid-sm-2-10">
            <div className="map">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3241.345138304571!2d139.62434715125684!3d35.66850278010007!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018f234f103ffff%3A0x9b1e467c05ac08de!2z5qCq5byP5Lya56S-44Oi44Oz44K_44O844K444Ol!5e0!3m2!1sja!2sjp!4v1630267252986!5m2!1sja!2sjp" width="100%" height="100%" style={{ border: 0 }} allowFullScreen="" loading="lazy" title="google map"></iframe>
            </div>
            <div className="address">
              <div>
                〒156-0057<br />
                東京都世田谷区上北沢4-10-18<br />
                TEL：03-3303-9871（代表）
              </div>
              <ul>
                <li>
                  <a href="https://goo.gl/maps/xoNCacYX1uc5e4JT7" target="_blank" rel="noreferrer">Google map<span>↗</span></a>
                </li>
                <li>
                  <a href="/pdf/map.pdf" target="_blank" rel="noreferrer">PDF<span>↗</span></a>
                </li>
              </ul>
            </div>
          </div>
        </Container>
      </Layout>
    </React.Fragment>
  );
};

export default AboutPage;
